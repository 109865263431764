import {StaticImage} from "gatsby-plugin-image"
import React from "react"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import {Col, Container, Row} from "reusecore/Layout"
import {SectionBackground, SectionTitle} from "reusecore/SectionTitle"
import Text from "reusecore/Text"
import ProblemMusicIndustoryWrapper from "./problemMusicIndustory.style"
import Fade from 'react-reveal/Fade'

const ProblemMusicIndustory = () => {
    return (
        <ProblemMusicIndustoryWrapper id="about">
            <Container>
                <Row>
                    <Col className="lg-5 md-6 sm-12">
                        <Fade left>
                            <StaticImage
                                src={'../../assets/images/about/about.png'}
                                imgClassName="about-image img-responsive"
                                placeholder="blurred"
                                alt="music industry problem"
                            />
                        </Fade>
                    </Col>
                    <Col className="lg-6 offset-lg-1 md-6 sm-12">
                        <Fade right>
                            <Box className="about-right-content">
                                <SectionTitle>
                                    <SectionBackground>
                                        <Heading as="h1">
                                            The Problem with the Music Industry
                                        </Heading>
                                    </SectionBackground>


                                </SectionTitle>

                                <Box className="about-list">
                                    <Text as="span"><b>Income inequality:</b> The top 1% of artists earn 77% of all
                                        recorded
                                        music income.</Text>
                                    <Text as="span"><b>Underpaid artists:</b> Most artists earn just 6 to 18% of revenue
                                        generated by their music.</Text>
                                    <Text as="span"><b>Greedy middlemen:</b> 85 to 90% of global music industry revenue
                                        goes
                                        to third parties.</Text>
                                    <Text as="span"><b>Outdated business model:</b> The music industry hasn’t yet caught
                                        up
                                        to the digital revolution.
                                    </Text>
                                </Box>


                            </Box>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </ProblemMusicIndustoryWrapper>
    )
}

export default ProblemMusicIndustory
