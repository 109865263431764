import {StaticImage} from "gatsby-plugin-image"
import React from "react"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import {Col, Container, Row} from "reusecore/Layout"
import {SectionBackground, SectionTitle} from "reusecore/SectionTitle"
import Text from "reusecore/Text"
import SolutionWrapper from "./solution.style"
import Fade from 'react-reveal/Fade'

const Solution = () => {
    return (
        <SolutionWrapper id="about">
            <Container>
                <Row>
                    <Col className="lg-6 offset-lg-1 md-6 sm-12">
                        <Fade left>
                            <Box className="about-right-content">
                                <SectionTitle>
                                    <SectionBackground>
                                        <Heading as="h1">
                                            The Solution: A Blockchain for Music That Benefits All
                                        </Heading>
                                    </SectionBackground>
                                </SectionTitle>
                                <Box className="about-list">
                                    <Text as="span"><b>Transparent interactions:</b> Direct lines of communication
                                        between
                                        music creators and consumers.
                                    </Text>
                                    <Text as="span"><b>Rewards-based ecosystem:</b> Royalty-free streaming, exclusive
                                        releases and access to live performances.
                                    </Text>
                                    <Text as="span"><b>PTM tokens:</b> Cuts out the need for middlemen by processing
                                        transactions via smart contracts.
                                    </Text>
                                    <Text as="span"><b>Fair business model:</b> Everyone in the music industry value
                                        chain
                                        is rewarded.
                                    </Text>
                                </Box>
                            </Box>
                        </Fade>
                    </Col>
                    <Col className="lg-5 md-6 sm-12">
                        <Fade right>
                            <StaticImage
                                src={'../../assets/images/solution/solution.png'}
                                imgClassName="about-image img-responsive"
                                placeholder="blurred"
                                alt="solution"
                            />
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </SolutionWrapper>
    )
}

export default Solution
