import styled from "styled-components";

import fundsBg from "assets/images/funds-bg.png";

const CoinFundWrapper = styled.div`
  padding: 110px 0 140px 0;
  position: relative;
  background-repeat: no-repeat;
  background-size: inherit;
  background-position: 72% 0px;

  &::before {
    position: absolute;
    right: 0;
    top: 0%;
    width: 100%;
    height: 100%;
    content: " ";
  }

  .btn-wrapper{
    margin-top: 35px;
  }
  .banner-btn {
      margin-top: 45px;
      a {
        width: 235px;
        font-size: 16px;
        color: #ffffff;
        padding: 18px 25px;
        align-items: center;
        justify-content: center;
        border: none;

        svg {
          margin-right: 10px;
        }
      }
  .btn-fill {
        transition: all 0.3s ease-in;
        background: rgb(37, 42, 213);
        background: linear-gradient(
          93deg,
          rgba(37, 42, 213, 1) 0%,
          rgba(122, 49, 222, 1) 52%,
          rgba(196, 56, 231, 1) 100%
        );
        &:hover {
          background: linear-gradient(
            93deg,
            rgba(196, 56, 231, 1) 0%,
            rgba(122, 49, 222, 1) 52%,
            rgba(37, 42, 213, 1) 100%
          );
        }
      }

  @media only screen and (max-width: 1024px) {
    background-size: 36%;
    background-position: 86% 41%;
    .heading {
      font-size: 25px;
      line-height: 40px;
      text-align: left;
    }
    .countdown-wrapper {
      .count {
        font-size: 45px;
      }
    }
  }

  @media only screen and (max-width: 912px) {
    background-position: 86% 70%;
    .coin-fund-content-left{
      text-align: center;
      margin-bottom: 80px;
    }
  }
  @media only screen and (max-width: 768px) {
    .countdown-wrap{
      width: 100%;
    }
  }
  @media only screen and (max-width: 480px) {
    background: none;
    padding: 50px 0;
    .heading {
      font-size: 25px;
      line-height: 40px;
    }
    .countdown-wrapper {
      .split {
        display: none;
      }
      .count {
        font-size: 35px;
      } 
      .countBox{
        flex-direction: column-reverse;
      }
    }
  }
`;

export default CoinFundWrapper;
