import React from "react"
import {Container, Row, Col} from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import {SectionTitle, SectionBackground} from "reusecore/SectionTitle"
import PotentiamGivesBackWrapper from "./potentiamGivesBack.style"
import Roll from 'react-reveal/Roll'

const PotentiamGivesBack = () => {
    return (
        <PotentiamGivesBackWrapper id="about">
            <Container>
                <Row>
                    <Col className="lg-6 md-6 sm-12">
                        <Roll left>
                            <Box className="about-right-content">
                                <SectionTitle>
                                    <SectionBackground>
                                        <Heading as="h1">
                                            How Potentiam Gives Back
                                        </Heading>
                                    </SectionBackground>

                                    <Text>
                                        Our decentralised blockchain network puts the money back in the hands of the
                                        people
                                        who deserve it – music creators. Every time you purchase Potentiam, you are
                                        directly
                                        supporting the musicians you love.
                                    </Text>
                                    <br/>
                                </SectionTitle>

                                <Box className="about-list">
                                    <Text as="span">Increased income for artists and reduced costs for listeners to
                                        access
                                        their music.
                                    </Text>
                                    <Text as="span">Artists can advertise and distribute their own music and meet
                                        potential
                                        collaborators.
                                    </Text>

                                </Box>


                            </Box>
                        </Roll>
                    </Col>
                    <Col className="lg-6 md-6 sm-12">
                        <Roll right>
                            <Box className="about-right-content">
                                <SectionTitle>
                                    <SectionBackground>
                                        <Heading as="h1">
                                            How Potentiam Rewards You
                                        </Heading>
                                    </SectionBackground>

                                    <Text>
                                        As well as staying up-to-date with your favourite musicians and discovering new
                                        artists, PTM token-holders also receive exclusive rewards, special rights, and
                                        privileges for participating on the platform. Members can earn extra rewards by
                                        performing different roles like bounty marketing, sending referrals, and being a
                                        moderator.
                                    </Text>
                                </SectionTitle>

                                <Box className="about-list">
                                    <Text as="span">We give back ~50% of revenue generated on the Potentiam platform.
                                    </Text>
                                    <Text as="span">Exclusive rewards and monetary incentives for fans – plus the chance
                                        to
                                        play your part in the music industry!

                                    </Text>

                                </Box>


                            </Box>
                        </Roll>
                    </Col>
                </Row>
            </Container>
        </PotentiamGivesBackWrapper>
    )
}

export default PotentiamGivesBack
