import React from "react"
import {Link} from "gatsby"

import {Container, Row, Col} from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import {SectionTitle, SectionBackground} from "reusecore/SectionTitle"
import OurVisionWrapper from "./ourVision.style"

const OurVision = () => {
    return (
        <OurVisionWrapper id="ourVision">
            <Container>
                <Row>
                    <Col className="sm-12 md-10 offset-md-1">
                        <Box className="coin-fund-content-left">
                            <SectionTitle className="text-center">
                                <SectionBackground>
                                    <Heading>
                                        Our Vision
                                    </Heading>
                                </SectionBackground>
                                <Text>
                                    Potentiam Network is the blockchain music platform run by music lovers, for music
                                    lovers – and we’re not just talking about consumers. No, we’re developing a
                                    one-of-a-kind network for musicians, artists, marketers, content writers,
                                    stagehands, and other key players in the music industry to connect and collaborate
                                    in new and exciting ways. The network’s core purpose is to facilitate interactions
                                    between music creators and consumers without the need for intermediaries. We do this
                                    by building interconnecting platforms that offer the same services at a fraction of
                                    the price. Through Potentiam, music creators can earn more, and music lovers also
                                    receive rewards for their contributions. Ready to join the revolution?
                                </Text>
                            </SectionTitle>

                            <Box className="banner-btn text-center">
                                <Link to="https://t.me/potentiamgroup" className="btn btn-fill">
                                    Join the Community
                                </Link>
                            </Box>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </OurVisionWrapper>
    )
}

export default OurVision
